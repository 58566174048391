import React, { useEffect } from "react";
import { render } from "react-dom";
import Layout from "./Shared/Layout";
import "../sass/client.scss";
import { Head, createInertiaApp } from "@inertiajs/react";
import { createRoot } from "react-dom/client";
import * as Sentry from "@sentry/react";

Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN_PUBLIC,
    integrations: [
        new Sentry.BrowserTracing({
            // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
            tracePropagationTargets: [
                "localhost",
                /^https:\/\/yourserver\.io\/api/,
            ],
        }),
        new Sentry.Replay(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

if (document.getElementById("app")) {
    createInertiaApp({
        progress: {
            color: '#EA1266',
          },
        resolve: (name) => {
            const pages = import.meta.glob("./Pages/**/*.jsx", { eager: false });
            let page = pages[`./Pages/${name}.jsx`];
            // page.default.layout ??= (page) => (
            //     <>
            //         <Head title="Meta Mata" />
            //         <Layout children={page} />
            //     </>
            // );

            return page();
        },
        setup({ el, App, props }) {
            createRoot(el).render(<App {...props} />);
        },
    });
}
